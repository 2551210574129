<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row class="form-container rounded-lg secondary--text">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลบทความ
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col cols="12">
          <image-uploader
            v-model="formData.imageUrls"
            :max-file="1"
            square
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <p class="mb-1">
            ชื่อบทความ <span class="primary--text">(TH)</span>
          </p>
          <v-text-field
            v-model="formData.name"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <p class="mb-1">
            ชื่อบทความ <span class="primary--text">(EN)</span>
          </p>
          <v-text-field
            v-model="formData.translate.name"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <div class="mb-1">
            ประเภทบทความ
          </div>
          <v-select
            v-model="formData.type"
            :rules="textBoxRules"
            :items="types"
            item-text="text"
            item-value="value"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <p class="mb-1">
            เนื้อหา <span class="primary--text">(TH)</span>
          </p>
          <Editor
            v-model="formData.content"
            image
            :disabled="loading"
            @image-added="onChangeContentImage($event, true, false)"
            @image-removed="onChangeContentImage($event, false, false)" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <p class="mb-1">
            เนื้อหา <span class="primary--text">(EN)</span>
          </p>
          <Editor
            v-model="formData.translate.content"
            image
            :disabled="loading"
            @image-added="onChangeContentImage($event, true, true)"
            @image-removed="onChangeContentImage($event, false, true)" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import BlogsProvider from '@/resources/blogs.provider'
import UploaderProvider from '@/resources/uploader.provider'
import ImageUploader from '@/components/ImageUploader.vue'
import Editor from '@/components/Editor.vue'

const BlogsService = new BlogsProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    ImageUploader,
    Editor
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      name: '',
      imageUrls: [],
      type: 'news',
      tags: [],
      postDate: '',
      content: '',
      translate: {
        name: '',
        content: ''
      }
    },
    types: [
      {
        text: 'ข่าวสาร',
        value: 'news'
      },
      {
        text: 'กิจกรรม',
        value: 'event'
      }
    ],
    contentImages: [],
    contentTranslateImages: []
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'BlogList' })
    },
    onChangeContentImage (payload, isAdd, isTranslate) {
      if (isAdd && isTranslate) {
        this.contentTranslateImages.push({
          unique: payload.unique,
          file: payload.file,
          mediaUrl: null
        })
      } else if (isAdd) {
        this.contentImages.push({
          unique: payload.unique,
          file: payload.file,
          mediaUrl: null
        })
      } else if (isTranslate) {
        const index = this.contentTranslateImages.findIndex((i) => i?.unique && i?.unique === payload)

        if (index !== -1) {
          this.contentTranslateImages.splice(index, 1)
        }
      } else {
        const index = this.contentImages.findIndex((i) => i?.unique && i?.unique === payload)

        if (index !== -1) {
          this.contentImages.splice(index, 1)
        }
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await BlogsService.getItemById(this.$route.params.id)

        this.formData = {
          ...data,
          imageUrls: data.imageUrls.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          }))
        }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true

        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        const imageUrls = await Promise.all(this.formData.imageUrls.map(async (img) => {
          if (img?.file) {
            const { data: image } = await UploaderService.uploadFile(img.file)

            return image?.publicUrl || ''
          }

          return img?.mediaUrl || ''
        }))

        const uploadedContentImages = await Promise.all(this.contentImages.map(async (img) => {
          if (img?.file) {
            const { data: image } = await UploaderService.uploadFile(img.file)

            return {
              unique: img.unique,
              mediaUrl: image?.publicUrl || ''
            }
          }

          return {
            mediaUrl: img?.mediaUrl || ''
          }
        }))

        const uploadedContentTranslateImages = await Promise.all(this.contentTranslateImages.map(async (img) => {
          if (img?.file) {
            const { data: image } = await UploaderService.uploadFile(img.file)

            return {
              unique: img.unique,
              mediaUrl: image?.publicUrl || ''
            }
          }

          return {
            mediaUrl: img?.mediaUrl || ''
          }
        }))

        const uploaded = [...uploadedContentImages, ...uploadedContentTranslateImages].filter((v) => 'unique' in v && v?.unique)

        uploaded.forEach((img) => {
          this.formData.content = this.formData.content.replace(img.unique, img.mediaUrl)
        })

        if (!this.isEdit) {
          await BlogsService.create({
            ...this.formData,
            imageUrls
          })
        } else {
          await BlogsService.updateItemById({
            ...this.formData,
            imageUrls
          })
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'BlogList' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
