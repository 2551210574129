import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class BlogsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/blogs', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/blogs/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/blogs', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/blogs/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/blogs/${id}`)
  }
}

export default BlogsProvider
